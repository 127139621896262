import {useCookies} from "react-cookie";
import React, {useEffect, useState} from "react";
import {Script} from "gatsby";

const MicrosoftClarity = () => {
  const [cookies] = useCookies();
  const [clarityConsent, setClarityConsent] = useState(true);

  useEffect(() => setClarityConsent(cookies.cookie_consent?.performance || cookies.cookie_consent?.marketing),
      [cookies]
  );

  const handleScriptLoad = () => {
    console.log('clarity handleScriptLoad');
    if (typeof (window as any)?.clarity === 'function') {
      console.log('Will set consent!');
      (window as any).clarity('consent');
    }
  };

  return (
      <>
        {
          clarityConsent ?
              <>
                <Script type="text/javascript" onLoad={handleScriptLoad}>{`
                  (function(c,l,a,r,i,t,y){
                      c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
                      t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
                      y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
                  })(window, document, "clarity", "script", "${process.env.GATSBY_MICROSOFT_CLARITY_TRACKING_ID || ''}");
                 `
                }
                </Script>
              </>
              :
              null
        }
      </>
  );
}

export default MicrosoftClarity;
